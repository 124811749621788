<template>
    <div class="container">
        <div class="title" style="display: flex;">
            <div style="flex-grow: 1;">
                <h1 v-if="currentTabIndex === 0">SPDCOST课程</h1>
                <h1 v-if="currentTabIndex === 1">SPDKIT课程</h1>
                <h1 v-if="currentTabIndex === 2">结构识图</h1>
                <p style="height: 32px;line-height: 20px">{{ tabData[currentTabIndex].description }}</p>
            </div>
            <div class="tab-item">
                <img v-if="currentTabIndex === 0" src="./tutorial-cost-active.png" />
                <img v-else @click="currentTabIndex = 0" src="./tutorial-cost.png" />
            </div>
            <div class="tab-item">
                <img v-if="currentTabIndex === 1" src="./tutorial-kit-active.png" />
                <img v-else @click="currentTabIndex = 1" src="./tutorial-kit.png" />
            </div>
            <div class="tab-item">
                <img v-if="currentTabIndex === 2" src="./tutorial-more-active.png" />
                <img v-else @click="currentTabIndex = 2" src="./tutorial-more.png" />
            </div>
        </div>
        <div class="player">
            <!-- 后面追加两个参数，开启高清480P和关闭弹幕 -->
            <iframe :src="tabData[currentTabIndex].playlist[currentPlayerIndex].url + '&high_quality=1&danmaku=0'"
                    scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true">
            </iframe>
            <div class="playlist">
                <div class="playlist-title" style="display: flex; align-items: center; justify-content: space-between;">
                    <div style="font-size: 14px; color: #333;">视频选集</div>
                    <div style="font-size: 12px; color: #999;">{{ currentPlayerIndex + 1 }}/{{ tabData[currentTabIndex].playlist.length }}</div>
                </div>
                <div v-for="(item, idx) in tabData[currentTabIndex].playlist" :key="item.name">
                    <div @click="currentPlayerIndex = idx" :class="['playlist-item', {'active': idx === currentPlayerIndex}]">
                    <span>
                        {{ tabData[currentTabIndex].playlist[idx].name }}
                        <img v-if="tabData[currentTabIndex].playlist[idx].isNew" class="isNew" style="" src="../../../public/static/images/isNew.svg" alt="">
                    </span>
                    <span>
                        {{ tabData[currentTabIndex].playlist[idx].duration }}
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'Tutorial',
        data() {
            return {
                tabData:{},
                currentTabIndex:0,
                currentPlayerIndex:0,
            }
        },
        mounted() {
            axios.get('static/tutorial_config.json').then(res => {
                if (res.data) {
                    this.tabData = res.data.data;
                    this.currentTabIndex = res.data.currentTabIndex;
                }
            })
        },
        watch: {
            currentTabIndex() {
                this.currentPlayerIndex = 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
    // 整体布局
    .container {
        background: no-repeat top left/auto 50% url("./bg_tutorial_left.png"),
        no-repeat top right/auto 50% url("./bg_tutorial_right.png"),
        linear-gradient(#161616 50%, #f3f3f3 50%);
        padding-top: 40px;
        padding-bottom: 80px;
        box-sizing: border-box;
        .title {
            max-width: 1300px;
            margin: 0 auto;
            height: 92px;
        }
        .player {
            max-width: 1300px;
            margin: 40px auto 0;
            height: 600px;

        }
    }

    // title 头部
    .title {
        display: flex;
        align-items: flex-end;

        h1 {
            font-family: YouSheBiaoTiHei;
            font-size: 40px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #ffffff;
        }

        p {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #999999;
        }

        .tab-item {
            flex-shrink: 0;
            margin-left: 12px;
            cursor: pointer;
        }
    }

    // 播放器
    .player {
        display: flex;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.09);

        iframe {
            flex-grow: 1;
        }

        .playlist {
            padding: 18px 20px;
            width: 300px;
            background: #f1f1f1;
            overflow-y: scroll;
            .playlist-title {
            }
            .playlist-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 28px;
                margin: 4px 0;
                padding: 0 8px;
                font-size: 12px;
                line-height: 28px;
                border-radius: 4px;
                cursor: pointer;

                span:first-child {
                    margin-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    & .isNew {
                        width: 17px;
                        transform: translateY(-1px);
                    }
                }

                span:last-child {
                    flex-grow: 1;
                    text-align: right;
                }
                &.active {
                    // 这个小的三角形表示播放按钮
                    &::before {
                        content: '';
                        display: inline-block;
                        width: 0; height: 0;
                        margin-right: 4px;
                        border-top: 7px solid transparent;
                        border-bottom: 7px solid transparent;
                        border-left: 10px solid #02d6dc;
                    }

                    color: #02d6dc;
                    background-color: #fff;
                }
            }
        }
    }
</style>
